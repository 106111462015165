.loading-main div.loading-box {
    min-height: 80vh;
    min-width: 100%;
}


.loading-main div.loading-box  h2 {
    text-align: center;
    color:#2c2172;
    font-size: 4rem;
}

.loading-main div.loading-box  h2 .spinner-border {
    height: 4rem;
    width: 4rem;
}
