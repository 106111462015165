main.settings-page .react-datepicker {
    border: none;
}

main .react-datepicker__month-container {
    margin-left: 10px;
}

.react-datepicker__header, .react-datepicker__day-name {
    font-family: 'Helvetica';
    text-transform: uppercase;
    background: #2c21720f;
}


.react-datepicker__day--highlighted {
    background: rgba(98, 213, 249, 0.51);
    font-weight: 900;
}

.react-datepicker__day--highlighted:hover {
    background: rgba(65, 204, 255, 0.68);
}

.react-datepicker__day-names {
    background: transparent;
    font-weight: 400 !important;
}

.react-datepicker__day-name {
    background: transparent;
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}

div.time label {
    display: block;
}

@media (min-width: 1030px) and (max-width: 1076px) {
    .days-selector .list-group-item {
        padding: .70rem 1.10rem;
    }

}

@media (min-width: 934px) and (max-width: 1030px) {
    .days-selector .list-group-item {
        padding: .60rem .9rem;
    }

}

@media (min-width: 914px) and (max-width: 934px) {
    .days-selector .list-group-item {
        padding: .60rem .9rem;
    }

}

@media (min-width: 842px) and (max-width: 914px) {
    .days-selector .list-group-item {
        padding: .60rem .8rem;
        font-size: 0.785rem;
    }

}

@media (min-width: 576px) and (max-width: 842px) {
    .days-selector {
        flex-direction: column;
    }
    .days-selector .list-group-item {
        padding: .75rem 1.25rem;
        font-size: 0.885rem;
    }

}
